const guide = {
    start_building: 'Yapmaya başla',
    get_started: 'Başlarken',
    categories: {
        featured: 'Popüler ve size özel',
        Traditional: 'Geleneksel web uygulaması',
        SPA: 'Tek sayfalık uygulama',
        Native: 'Doğal',
        MachineToMachine: 'Makineden makineye',
        Protected: 'SDK Entegrasyonu Olmayan',
        ThirdParty: 'Üçüncü taraf uygulama',
    },
    filter: {
        title: "Framework'ü filtrele",
        placeholder: 'Framework ara',
    },
    checkout_tutorial: 'Öğreticiyi kontrol et {{name}}',
    do_not_need_tutorial: 'Eğer bir öğreticiye ihtiyacınız yoksa, çerçeve kılavuzu olmadan devam edebilirsiniz',
    finish_and_done: 'Bitir ve tamamla',
    cannot_find_guide: 'Kılavuzunuzu bulamıyor musunuz?',
    describe_guide_looking_for: 'Aramakta olduğunuz kılavuzu açıklayın',
    request_guide_successfully: 'Talebiniz başarıyla gönderildi. Teşekkür ederim!',
    app: {
        select_framework_or_tutorial: 'Bir çerçeve veya öğretici seçin',
        guide_modal_title: 'SDK ve kılavuzlarla başlayın',
        modal_subtitle: 'Önceden oluşturulmuş SDK ve öğreticilerimizle uygulama geliştirme sürecinize hız kazandırın.',
        select_a_framework: 'Bir çerçeve seçin',
        continue_without_framework: 'Çerçevesiz uygulama oluştur',
        describe_guide_looking_for_placeholder: "Örneğin, Logto'u Angular uygulamama entegre etmek istiyorum.",
    },
    api: {
        modal_title: 'Öğreticilerle başlayın',
        modal_subtitle: 'Önceden oluşturulmuş öğreticilerimizle uygulama geliştirme sürecinize hız kazandırın.',
        select_a_tutorial: 'Bir öğretici seçin',
        continue_without_tutorial: 'Öğretici olmadan devam et',
        describe_guide_looking_for_placeholder: "Örneğin, API'mı deno ile korumak istiyorum.",
    },
};
export default Object.freeze(guide);
