const menu = {
    profile: 'Profil',
    language: 'Dil',
    appearance: {
        label: 'Görünüm',
        light: 'Açık mod',
        dark: 'Koyu mod',
        system: 'Sistemle senkronize et',
    },
    sign_out: 'Çıkış Yap',
};
export default Object.freeze(menu);
