const invitation = {
    find_your_tenants: 'Kiracılarınızı Bulun',
    find_tenants_description: 'E-posta adresiniz zaten birden fazla kiracı ile kayıtlı olabilir. Var olanlara katılabilir veya devam ederek yeni bir tane oluşturabilirsiniz.',
    create_new_tenant: 'Yeni Kiracı Oluştur',
    email_not_match_title: 'Şu anda\n{{email}} olarak oturum açtınız',
    email_not_match_description: 'Daveti kabul etmek ve organizasyonun bir üyesi olmak için doğru hesapla oturum açınız.',
    switch_account: 'Başka bir hesaba oturum açın',
    invalid_invitation_status: 'Geçersiz davet. Lütfen yöneticiyle iletişime geçerek tekrar deneyin.',
    invitation_not_found: 'Davet bulunamadı. Lütfen yöneticiyle iletişime geçin.',
};
export default Object.freeze(invitation);
